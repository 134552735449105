<template>
  <div>
    <el-form :inline="true">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="5" :md="24">
            <el-form-item label="班级名称：">
              <el-input
                v-model="queryParam.className"
                @keyup.enter.native="search"
                placeholder="请输入"
                clearable
                class="w100"
              />
            </el-form-item>
          </el-col>
          <el-col :lg="8" :md="24">
            <el-form-item label="开班日期：">
              <date-picker
                ref="dataPicker"
                :dateTime="dateTime"
                class="w100"
                @updateView="datetTimeGet"
              ></date-picker>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="课程名称：">
              <el-input
                v-model="queryParam.courseName"
                @keyup.enter.native="search"
                placeholder="请输入"
                clearable
                class="w100"
              />
            </el-form-item>
          </el-col>
          <template v-if="advanced">
            <el-col :lg="5" :md="24">
              <el-form-item label="状态：">
                <el-select
                  v-model="queryParam.stateValue"
                  placeholder="请选择状态"
                  class="tb-smselect"
                >
                  <el-option
                    v-for="item in stateArray"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </template>

          <search-query
            @updateView="updateView"
            @clearQuery="clearQuery"
            @searchQuery="search"
            :showMore="true"
          ></search-query>
        </el-row>
      </div>
    </el-form>

    <!-- 表格 -->
    <el-card shadow="never" style="overflow-y: scroll; height: 600px">
      <div slot="header" class="flex" style="align-items: center">
        <span>商户班级数据</span>
        <div class="flex_1"></div>
        <!-- <el-button type="primary" size="mini" @click="addClass()"
          >新增班级
        </el-button> -->
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table-bar fixed :static="true">
        <el-table
          :key="tableKey"
          :data="dataList"
          fit
          :highlight-current-row="true"
          @sort-change="tableSortAll"
          :default-sort="{ prop: 'open_date', order: 'descending' }"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '班级名称'">
                <span class="column-link" @click="classManage(scope.row)">{{
                  scope.row.name
                }}</span>
              </template>
              <template v-else-if="item.label === '班级类型'">
                <span v-if="scope.row.type">{{ scope.row.type }}</span>
                <span v-else>--</span>
              </template>
              <template v-else-if="item.label === '上课进度'">
                <span
                  >{{ Number(scope.row.finished) }}/{{ scope.row.total }}</span
                >
              </template>
              <template v-else-if="item.label === '最近排课日期'">
                <span v-if="scope.row.start_time && scope.row.end_time">
                  {{ scope.row.start_time | formatDateStart
                  }}{{ scope.row.end_time | formatDateEnd }}
                </span>
                <span v-else>--</span>
              </template>
              <template v-else-if="item.label === '课程名称'">
                <span v-if="scope.row.courseName">
                  {{ scope.row.courseName }}
                </span>
                <span v-else>--</span>
              </template>
              <template v-else-if="item.label === '状态'">
                <span
                  class="span-state"
                  :style="
                    'backgroundColor' + ':' + getStateColor(scope.row.status)
                  "
                ></span>
                <span v-text="scope.row.status"></span>
              </template>
              <template v-else-if="item.label === '结业日期'">
                <span
                  v-if="
                    !scope.row.finished_date ||
                    scope.row.finished_date === '0001-01-01' ||
                    scope.row.finished_date === '1900-01-01'
                  "
                  >未结业</span
                >
                <span v-else>{{ scope.row.finished_date }}</span>
              </template>
              <template v-else-if="item.label === '操作'">
                <el-button type="text" @click="classManage(scope.row)"
                  >管理</el-button
                >
                <el-button type="text" @click="delClass(scope.row)"
                  >删除</el-button
                >
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-card>
    <!-- add-class -->
    <add-class
      :show="classDialog"
      @close="classDialog = false"
      @addClassForm="addClassForm"
    ></add-class>
  </div>
</template>

<script>
import TextButton from "@/components/TextButton";
import SearchQuery from "@/components/SearchQuery";
// import AddClass from "@/components/merchantClass/add-class1";
import getStateColor from "@/mixins/getStateColor";
import datePicker from "@/components/date-picker";
import {
  getClassAll,
  delClass,
  getProgress,
  addClassData,
  copyDoubleCla,
} from "@/api/merchantClass";
// 默认展示列
const baseColSetting = [
  {
    label: "商户名称",
    prop: "merchantName",
    state: true,
    align: "left",
    width: "100",
  },
  {
    label: "班级名称",
    prop: "name",
    state: true,
    align: "left",
    // sortable: true,
    width: "100",
  },
  {
    label: "班级类型",
    prop: "type",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "开班日期",
    prop: "open_date",
    state: true,
    sortable: true,
    align: "center",
    width: "150",
  },
  {
    label: "上课进度",
    prop: "",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "最近排课日期",
    prop: "",
    state: true,
    sortable: true,
    align: "center",
    width: "200",
  },
  {
    label: "课程名称",
    prop: "courseName",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "状态",
    prop: "status",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "来源",
    prop: "source",
    state: false,
    align: "center",
    width: "100",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "130",
  },
];
export default {
  components: { TextButton, SearchQuery, datePicker },

  mixins: [getStateColor],

  mounted() {
    this.getClassAll();
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  data() {
    return {
      advanced: false,
      dataList: [
        {
          merchantName: '欧莱雅',
          name:'1年级',
          type:'常规',
          state:"结业",
        }
      ],
      dateTime: [],
      tableKey: 1,
      queryParam: {
        // 查询参数
        campus_id: [],
        status: null,
      },
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      classOrderBy: "openDate", // 排序依据
      classIsAsc: false, // 默认为升序
      classDialog: false,
      // stateValue: false, // 默认未结业状态
      stateArray: [
        {
          value: false,
          label: "未结业",
        },
        {
          value: true,
          label: "已结业",
        },
      ],
    };
  },
  methods: {
    datetTimeGet(data) {
      if (data === null) {
        this.minStartTime = "";
        this.maxStartTime = "";
      } else {
        this.minStartTime = data[0];
        this.maxStartTime = data[1];
      }
    },
    // 清空选项
    clearQuery() {
      this.queryParam = {
        status: null,
      };
      this.$refs.dataPicker.time = [];
      this.maxStartTime = "";
      this.minStartTime = "";
    },

    search() {
      this.pagination.currentPage = 1;
      this.getClassAll();
    },

    // 获取全部班级列表
    async getClassAll() {
      // this.dataList = [];
      const res = await getClassAll({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        campusIdSet: this.queryParam.campus_id,
        className: this.queryParam.className,
        orderBy: this.classOrderBy,
        isAsc: this.classIsAsc,
        productName: this.queryParam.productName,
        courseName: this.queryParam.courseName,
        minOpenDate: this.minStartTime ? this.minStartTime + " 00:00:00" : null,
        maxOpenDate: this.maxStartTime ? this.maxStartTime + " 23:59:59" : null,
        status: this.queryParam.status ? this.queryParam.status : null,
      });
      const dataList = res.body.list;
      dataList.forEach((item, index) => {
        item.finished = "";
        item.total = "";
        this.getProgress(item.class_id, index);
      });
      // this.dataList = dataList;
      // this.pagination.total = res.body.total;
    },

    // 获取班级上课进度
    getProgress(id, idx) {
      getProgress({
        classId: id,
      }).then((res) => {
        this.dataList[idx].finished = res.body.finished;
        this.dataList[idx].total = res.body.total;
      });
    },

    // 班级列表排序
    tableSortAll(column) {
      switch (column.prop) {
        case "name":
          this.classOrderBy = "className";
          break;
        case "open_date":
          this.classOrderBy = "openDate";
          break;
        case "courseName":
          this.classOrderBy = "courseName";
          break;
        case "lately_lesson":
          this.classOrderBy = "nextLessonStartTime";
          break;
      }
      switch (column.order) {
        case "ascending":
          this.classIsAsc = true;
          break;
        case "descending":
          this.classIsAsc = false;
      }
      this.getClassAll();
    },

    // 管理班级
    classManage(row) {
      if (!row.productId) {
        this.$router.push({
          path: "/merchantClass/class-detail",
          query: {
            classID: row.class_id,
            courseId: row.courseId,
          },
        });
      } else {
        this.$router.push({
          path: "/senate/class-detail",
          query: {
            classID: row.class_id,
            courseId: row.courseId,
            productId: row.productId,
          },
        });
      }
    },

    // 删除班级
    delClass(row) {
      this.$confirm("确定删除当前班级吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await delClass({
            id: row.class_id,
          });
          window.$msg("删除成功");
          this.getClassAll();
        })
        .catch(() => {
          return false;
        });
    },

    // 新增班级
    // addClass() {
    //   this.classDialog = true;
    // },
    addClassForm(data) {
      if (data.classType === "常规") {
        let addClass = {
          // addClassForm:data.addClassForm,
          name: data.name,
          course_id: data.course_id,
          open_date: data.open_date,
          lctype: data.lctype,
          type: data.type,
          teacherIds: data.teacherIds,
        };
        addClassData({ ...addClass }).then((res) => {
          if (res.state === "success") {
            window.$msg("新增成功");
            this.search();
          }
        });
      } else if (data.classType === "双师") {
        let addClass = {
          name: data.name,
          copyClassId: data.class_id,
          assistantIds: data.assistantIds,
          studentIds: data.studentIds,
          course_id: data.course_id,
          type: data.classType,
          //   private Array<String> assistantIds; //助教
          // private Array<String> studentIds; //学员
        };
        copyDoubleCla({ ...addClass }).then((res) => {
          if (res.state === "success") {
            window.$msg("新增成功");
            this.search();
          }
        });
      }
    },

    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    updateView(data) {
      this.advanced = data;
    },

    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getClassAll();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getClassAll();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>
